<div class="action-bar">
  <div class="nav-content" style="margin-top: 10px;">
      <h3 class="head-title">
          {{name}}
      </h3>
      <div class="flex1"></div>
      <div class="action-list-item" *ngIf="buttonText">
          <div class="action-item">
              <button mat-button (click)="create()" class="enbutton primary">{{buttonText}}</button>
          </div>
      </div>
  </div>
</div>
<nav mat-tab-nav-bar *ngIf="tabs">
  <a mat-tab-link *ngFor="let link of tabs"
    [queryParams]="queryParams"
    [routerLink]="link.path" routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">{{link.label}}</a>
</nav>
